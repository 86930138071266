import { FEATURES } from '../Config';

type Feature = 'interview';

class FeaturesService {
  private readonly featureSet = new Set<string>();

  constructor() {
    const featureEnv = FEATURES;
    if (featureEnv) {
      this.featureSet = new Set<string>(featureEnv.split(','));
    }
  }

  enabled(feature: Feature): boolean {
    return this.featureSet.has(feature);
  }
}

export const features = new FeaturesService();
